import { Button, ButtonProps } from '@mui/material';

export const CustomButton = ({
  children,
  variant,
  sx,
  ...btnProps
}: ButtonProps) => {
  return (
    <Button
      disableElevation
      variant={variant || 'contained'}
      sx={{
        borderRadius: 60,
        textTransform: 'none',
        ...(variant === 'contained' && {
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
        }),
        ...sx,
      }}
      {...btnProps}
    >
      {children}
    </Button>
  );
};
