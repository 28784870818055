import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';

import axios, { AxiosError } from 'axios';
import { User } from 'types';
import Auth from 'helpers/auth';
import { useRouter } from 'next/router';

type ContextValue = [
  /**User info and isLoading object */
  { user: null | User; isLoading: boolean },
  /**logout user and clear localStorage*/
  () => void,
  /**update user context */
  Dispatch<
    SetStateAction<{
      user: null | User;
      isLoading: boolean;
    }>
  >,
  () => Promise<void>,
];

const UserContext = createContext<ContextValue | undefined>(undefined);

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export const UserProvider: FC = ({ children }) => {
  const router = useRouter();
  const [userData, setUserData] = useState<{
    user: null | User;
    isLoading: boolean;
  }>({
    user: null,
    isLoading: true,
  });
  // const [isLoading, setLoading] = useState<boolean>(true);

  const logout = useCallback(async () => {
    await router.push('/');
    Auth.clearLocalStorage();
    setUserData({ user: null, isLoading: false });
  }, [router]);

  interface UserDetailsResponse {
    data: {
      user: User;
    };
    message: string;
    statusCode: number;
  }

  const apiUrl = process.env.NEXT_PUBLIC_API_HOST!;
  const protocol = process.env.NEXT_PUBLIC_API_PROTOCOL!;

  const fetchUserData = async () => {
    return await axios
      .get<UserDetailsResponse>(
        // 'https://pod-backend.herokuapp.com/api/v1/user/details',
        `${protocol}://${apiUrl}/api/v1/user/details`,
        {
          headers: {
            Authorization: Auth.getToken(),
          },
        },
      )
      .then(({ data }) => {
        // setUserData(data.data.user);
        console.log({ user: data?.data?.user, isLoading: false });
        setUserData({ user: data?.data?.user, isLoading: false });
        // setLoading(false);
      })
      .catch((_e: AxiosError<any, any>) => {
        // setUserData(null);
        // setLoading(false);
        console.log(_e);
        setUserData({ user: null, isLoading: false });
      });
  };

  useEffect(() => {
    axios
      .get<UserDetailsResponse>(
        // 'https://pod-backend.herokuapp.com/api/v1/user/details',
        `${protocol}://${apiUrl}/api/v1/user/details`,
        {
          headers: {
            Authorization: Auth.getToken(),
          },
        },
      )
      .then(({ data }) => {
        // setUserData(data.data.user);
        console.log({ user: data?.data?.user, isLoading: false });
        setUserData({ user: data?.data?.user, isLoading: false });
        // setLoading(false);
      })
      .catch((_e: AxiosError<any, any>) => {
        // setUserData(null);
        // setLoading(false);
        console.log(_e);
        setUserData({ user: null, isLoading: false });
      });
  }, [apiUrl, protocol]);

  const value: ContextValue = [userData, logout, setUserData, fetchUserData];
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
