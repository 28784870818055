import { createContext, FC, useState, useEffect, useContext } from 'react';

import { useRouter } from 'next/router';

type ContextValue = string | undefined;

const CampaignContext = createContext<ContextValue | undefined>(undefined);

export function useCampaign() {
  const context = useContext(CampaignContext);

  return context;
}

export const CampaignProvider: FC = ({ children }) => {
  const router = useRouter();
  const [campaign, setCampaign] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (router.query?.campaign) {
      setCampaign(router.query?.campaign as string | undefined);
    }
  }, [router.query?.campaign]);

  const value: ContextValue = campaign;
  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};
