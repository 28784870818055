import { ReactNode, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export type FaqQNAType = { question: string; answer: ReactNode };

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: '25px 0',
    '& p': {
      fontWeight: 500,
      fontSize: '18px',
      color: '#111',
    },
  },
  '& p': {
    color: 'rgba(17, 17, 17, 0.8)',
    fontSize: '16px',
    fontWeight: 400,
    marginRight: '91px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}));

export const Faq = ({
  index,
  question,
  answer,
}: FaqQNAType & { index: number }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledAccordion
      expanded={open}
      elevation={0}
      disableGutters
      onClick={() => {
        setOpen((s) => !s);
      }}
    >
      <AccordionSummary
        // expandIcon={<AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{`${index + 1}. ${question}`}</Typography>
        {open ? (
          <RemoveIcon sx={{ ml: 'auto' }} />
        ) : (
          <AddIcon sx={{ ml: 'auto' }} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
};
