import { SxProps } from '@mui/material';
import { Control } from 'react-hook-form';
import { CustomTextField } from '.';

function getUptoTwo(float: number) {
  const [int, deci] = float.toString().split('.');
  const sum = int + '.' + (deci ? deci.slice(0, 2) : '');
  if (isNaN(+sum)) return '';
  return +sum;
}

interface CustomTextFieldAmountProps {
  name: string;
  control: Control<any, object>;
  currency: 'inr' | 'usd';
  placeholder: string;
  autoComplete?: string;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  sx?: SxProps;
  variant?: 'filled' | 'outlined' | 'standard';
  label?: string;
  InputProps?: any;
}

export const CustomTextFieldAmount = ({
  name,
  control,
  currency,
  placeholder,
  textAlign,
  autoComplete,
  sx,
  label,
  variant,
}: CustomTextFieldAmountProps) => {
  return (
    <CustomTextField
      variant={variant}
      label={label}
      control={control}
      name={name as string}
      placeholder={placeholder}
      fullWidth
      inputProps={{ style: { textAlign } }}
      autoComplete={autoComplete || 'off'}
      sx={sx}
      transform={{
        input: (value: string) => {
          if (!value) {
            return '';
          }
          return value;
        },
        output: (e) => {
          const value = e.target.value;
          const isValid = /^[0-9,.]*$/.test(value);
          if (!isValid) {
            return '';
          }
          const floatVal = parseFloat(e.target.value.replace(/,/g, ''));
          const output = getUptoTwo(floatVal);
          if (value[value.length - 1] === '.') {
            return (
              output.toLocaleString(currency === 'inr' ? 'en-IN' : 'en-US') +
              '.'
            );
          }
          return output.toLocaleString(currency === 'inr' ? 'en-IN' : 'en-US');
        },
      }}
    />
  );
};

export const CustomTextFieldAmountNoDecimal = ({
  name,
  control,
  currency,
  placeholder,
  textAlign,
  autoComplete,
  sx,
  variant,
  label,
  InputProps,
}: // variant = 'standard',
CustomTextFieldAmountProps) => {
  return (
    <CustomTextField
      variant={variant}
      control={control}
      label={label}
      name={name as string}
      placeholder={placeholder}
      fullWidth
      inputProps={{ style: { textAlign } }}
      autoComplete={autoComplete || 'off'}
      onPaste={(e) => {
        e.preventDefault();
      }}
      InputProps={InputProps}
      sx={sx}
      transform={{
        input: (value: string) => {
          if (!value) {
            return '';
          }
          return value;
        },
        output: (e) => {
          const value = e.target.value;
          const isValid = /^[0-9,]*$/.test(value);
          if (!isValid) {
            return value.slice(0, value.length - 1);
          }
          const floatVal = parseFloat(e.target.value.replace(/,/g, ''));
          const output = getUptoTwo(floatVal);
          return output.toLocaleString(currency === 'inr' ? 'en-IN' : 'en-US');
        },
      }}
    />
  );
};
