import { styled } from '@mui/system';

export const OutlinedButton = styled('button')<{ color?: string }>(
  ({ theme, color }) => ({
    textDecoration: 'none',
    fontFamily: 'inherit',
    fontSize: 16,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    paddingTop: 7,
    paddingBottom: 7,
    backgroundColor: 'transparent',
    // border: `1px solid ${theme.palette.primary.main}`,
    border: 'none',
    boxShadow: `inset 0px 0px 0px 1px ${color || theme.palette.primary.main}`,
    color: color || theme.palette.primary.main,
    fontWeight: 500,
    paddingLeft: 24,
    paddingRight: 24,
    cursor: 'pointer',
  }),
);
