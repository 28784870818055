import { Grid, Typography, Box } from '@mui/material';
import siteLogo from 'assets/images/logo.svg';
import LinkedinIcon from 'assets/images/linkedin-icon.svg';
import TwitterIcon from 'assets/images/twitter-icon.svg';
import InstagramIcon from 'assets/images/instagram-icon.svg';
import Link from 'components/Link';
// import Image from 'next/image';

export default function Footer() {
  const onLinkHandler = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        padding: {
          xs: '20px',
          md: '20px 37px',
        },
        display: 'block',
        background: '#111',
      }}
    >
      <Grid container>
        <Grid item xs={12} md={1}>
          <Link
            href="/"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '60px',
              '& img': {
                width: '100%',
              },
            }}
          >
            <img src={siteLogo} alt="logo" />
          </Link>
          {/* <Typography className={classes.footerCopy} component="p">Copyright © 2021</Typography> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            sx={{
              fontSize: 9,
              lineHeight: '12px',
              color: 'rgba(255, 255, 255, 0.5)',
              marginTop: {
                xs: '20px',
                md: 0,
              },
            }}
            component="p"
          >
            POD is owned by Crowdpouch Ventures Services Private Limited and
            reserves all rights to the assets, content, services, information,
            and products and graphics in the website but third party content.
            Crowdpouch does not solicit, advertise, market any of the users
            registered with POD, neither does it solicit investors by offering
            leagues/schemes/competitions etc. related to securities markets. POD
            hereby clarifies that it does not carry any resemblance to the stock
            exchange nor does it facilitate trading of securities nor does it
            act like a broker/agent/media for raising funds. Investment through
            POD does not carry rights of renunciation. Investors are cautioned
            that POD operates in an unregulated space hence, investment through
            POD is subject to investment risk. Investments in startups are
            highly illiquid.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: 'block',
              position: {
                xs: 'absolute',
                md: 'static',
              },
              top: '20px',
              right: {
                350: '5px',
                xs: '20px',
                sm: '37px',
              },
              '& ul': {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                margin: 0, ///new
                padding: 0,
                '& li': {
                  display: 'flex',
                  flexWrap: 'wrap',
                  cursor: 'pointer',
                  marginRight: '3px',
                  marginLeft: {
                    xs: '6px',
                    lg: '10px',
                  },
                  '& img': {
                    maxWidth: {
                      xs: '20px',
                      lg: 'inherit',
                    },
                  },
                },
              },
            }}
          >
            <Box component="ul">
              <li>
                <Link
                  sx={{
                    textDecoration: 'none',
                    color: '#fff',
                    lineHeight: {
                      xs: '20px',
                      lg: '24px',
                    },
                    fontSize: {
                      xs: '10px',
                      lg: '14px',
                    },
                  }}
                  href="https://pod-world.s3.ap-south-1.amazonaws.com/Contact_1.pdf"
                  target="__blank"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  sx={{
                    textDecoration: 'none',
                    color: '#fff',
                    lineHeight: {
                      xs: '20px',
                      lg: '24px',
                    },
                    fontSize: {
                      xs: '10px',
                      lg: '14px',
                    },
                  }}
                  href="/terms-service"
                  target="__blank"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  sx={{
                    textDecoration: 'none',
                    color: '#fff',
                    lineHeight: {
                      xs: '20px',
                      lg: '24px',
                    },
                    fontSize: {
                      xs: '10px',
                      lg: '14px',
                    },
                  }}
                  href="/privacy-policy"
                  target="__blank"
                >
                  Privacy Policy
                </Link>
              </li>
              <li
                onClick={() => onLinkHandler('https://twitter.com/pod_india')}
              >
                <img width={20} height={20} src={TwitterIcon} alt="" />
              </li>
              <li
                onClick={() =>
                  onLinkHandler(
                    'https://www.linkedin.com/company/pod-investments/',
                  )
                }
              >
                <img width={20} height={20} src={LinkedinIcon} alt="" />
              </li>
              <li
                onClick={() =>
                  onLinkHandler('https://www.instagram.com/pod_india/')
                }
              >
                <img width={20} height={20} src={InstagramIcon} alt="" />
              </li>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'block',
              textAlign: 'right',
              fontSize: '12px',
              lineHeight: '14px',
              color: 'rgba(255, 255, 255, 0.5)',
              position: {
                xs: 'absolute',
                md: 'static',
              },
              right: {
                xs: '20px',
                sm: '37px',
              },
              top: '50px',
              marginTop: {
                xs: 0,
                md: '12px',
              },
            }}
          >
            Proudly made for India
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
