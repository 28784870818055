import { parse } from 'query-string';

export function toFixedIfNecessary(value: number | string, dp: number) {
  return +parseFloat(value.toString()).toFixed(dp);
}

export function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function youtubeLinkToThumbnailUrl(url: string) {
  // const video;
  const queryParamsString = url.split('?')[1];
  const queryParams = parse(queryParamsString);
  // console.log(videoId);
  return `https://img.youtube.com/vi/${queryParams.v}/hqdefault.jpg`;
  // https://img.youtube.com/vi/<insert-youtube-video-id-here>/hqdefault.jpg
}

export function daysBetween(date1: Date, date2: Date): number {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.max(date1.valueOf() - date2.valueOf(), 0);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}
