import {
  Avatar,
  Badge,
  IconButton,
  Menu,
  SxProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useUser } from 'context/user.context';
import { useState, MouseEvent } from 'react';
import { NavLink } from './NavLinks';

interface ProfileButtonProps {
  name: string;
  image?: string;
  handleLogout: () => Promise<void>;
  onClick: () => void;
  sx?: SxProps;
}

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    // right: -3,
    // top: 13,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
    // bottom: -10,
    top: 35,
    right: 35,
  },
}));

export const ProfileButton = ({
  image,
  handleLogout,
  name,
  onClick,
  sx,
}: ProfileButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const open = Boolean(true);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [userInfo] = useUser();
  const { user: userData } = userInfo;

  if (!userData) {
    return null;
  }
  const { role_id, status } = userData;
  const approved = userData.company?.approved;

  // console.log(role_id);
  // console.log(status);
  // console.log(approved);
  // console.log('---------------------');
  // console.log(role_id === 2 && status !== 4 && approved !== 'approved');

  return (
    <>
      <IconButton
        // disableFocusRipple
        disableRipple
        edge="end"
        onClick={handleClick}
        sx={{
          p: 0,
          ...sx,
        }}
      >
        <StyledBadge badgeContent={userData.notification_count} color="primary">
          <Avatar sx={{ bgcolor: '#111111' }} alt={name} src={image}>
            {name}
          </Avatar>
        </StyledBadge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            p: 1,
            overflow: 'visible',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <NavLink
          mobile={false}
          active={false}
          href={role_id === 1 ? '/investor-portfolio' : '/investor-summary'}
          onClick={onClick}
          // disabled={!(role_id === 2 && status === 4 && approved === 'approved')}
          disabled={
            role_id === 1 ? false : !(status === 4 && approved === 'approved')
          }
        >
          Dashboard
        </NavLink>
        <Typography
          sx={{ mx: 1.5, mt: 1, cursor: 'pointer' }}
          onClick={handleLogout}
        >
          Logout
        </Typography>
      </Menu>
    </>
  );
};
